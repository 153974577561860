<template>
  <div>
    <v-container>
      <h1 class="pb-6">Livestock Observations</h1>

      <v-card rounded="lg" class="pa-6">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <h4>Select From List Of Registered Animals</h4>
              <v-select outlined></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <h4>Select Option</h4>
              <v-select outlined></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <h4>Select Type Of Observation</h4>
              <v-select outlined></v-select>
            </v-col>
            <v-col cols="12">
              <h4>Comment</h4>
              <v-textarea outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <h4>Upload An Image</h4>
              <v-file-input outlined prepend-icon="mdi-camera"></v-file-input>
            </v-col>
            <v-col cols="12" md="6">
              <h4>Attach Veterimary Document</h4>
              <v-file-input outlined></v-file-input>
            </v-col>
            <v-col cols="12" md="6">
              <h4>Add Sensor Reading</h4>
              <v-file-input outlined prepend-icon="mdi-leak"></v-file-input>
            </v-col>
            <v-col class="d-flex justify-center" cols="12">
              <v-btn depressed color="primary">Submit</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
